// // CookieProvider.tsx
// "use client";
// import {
//   createContext,
//   useContext,
//   useState,
//   useEffect,
//   ReactNode,
// } from "react";
// import {
//   getLanguage,
//   getTheme,
//   setLanguage,
//   setTheme,
// } from "../utils/cookiesManager";
// import enflag from "@/assets/flags/englishFlag.png";
// import krflag from "@/assets/flags/koreaFlag.png";
// import chflag from "@/assets/flags/chinaFlag.png";
// import jpflag from "@/assets/flags/japanFlag.png";

// type CookiesContextType = {
//   theme: boolean; // Change to boolean
//   language: string;
//   setTheme: (theme: boolean) => {}; // Set theme as boolean
//   setLanguage: (language: string) => {};
// };

// export const languageArr = [
//   { key: "kr", value: "한국어", icon: krflag },
//   { key: "cn", value: "中文", icon: chflag },
//   { key: "jp", value: "日本語", icon: jpflag },
//   { key: "en", value: "English", icon: enflag },
// ];

// // export const languageArr = [
// //   { code: "kr", key: "한국어", value: "한국어", icon: <KoreanIcon /> },
// //   { code: "cn", key: "中文", value: "中文", icon: <ChineseIcon /> },
// //   { code: "jp", key: "日本語", value: "日本語", icon: <JapaneseIcon /> },
// //   { code: "en", key: "ENGLISH", value: "English", icon: <EnglishIcon /> },
// // ];

// // Define the context
// const CookieContext = createContext<CookiesContextType | undefined>(undefined);

// // Provide the context
// export const CookiesProvider = ({ children }: { children: ReactNode }) => {
//   const [theme, setThemeState] = useState<boolean>(false);
//   const [language, setLanguageState] = useState<string>("");

//   useEffect(() => {
//     (async () => {
//       const savedTheme = await getTheme();
//       const savedLanguage = await getLanguage();
//       if (savedTheme !== undefined) setThemeState(savedTheme);
//       if (savedLanguage) setLanguageState(savedLanguage);
//     })();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   const updateTheme = async (selectedTheme: boolean) => {
//     setThemeState(selectedTheme);
//     await setTheme(selectedTheme);
//   };

//   const updateLanguage = async (selectedLanguage: string) => {
//     setLanguageState(selectedLanguage);
//     await setLanguage(selectedLanguage);
//   };

//   return (
//     <CookieContext.Provider
//       value={{
//         theme,
//         language,
//         setTheme: updateTheme,
//         setLanguage: updateLanguage,
//       }}
//     >
//       {children}
//     </CookieContext.Provider>
//   );
// };

// export const useCookieContext = () => {
//   const context = useContext(CookieContext);
//   if (!context) {
//     throw new Error("useCookieContext must be used within a CookiesProvider");
//   }
//   return context;
// };

// CLIENT SIDE ************************
// CookieProvider.tsx
"use client";
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import {
  getLanguage,
  getTheme,
  setLanguage,
  setTheme,
  getSound,
  setSound,
  getBackgroundVolume,
  setBackgroundVolume,
  getSceneVolume,
  setSceneVolume,
  getUserCookies,
  setUserCookies,
} from "../utils/cookiesManager";
import enflag from "@/assets/flags/englishFlag.png";
import krflag from "@/assets/flags/koreaFlag.png";
import chflag from "@/assets/flags/chinaFlag.png";
import jpflag from "@/assets/flags/japanFlag.png";
import { userDataVar } from "../utils/apollo";
import { useReactiveVar } from "@apollo/client";

type CookiesContextType = {
  sound: boolean;
  sceneVolume: number;
  backgroundVolume: number;
  theme: boolean;
  language: string;
  userCookies: userCookies | undefined;
  setTheme: (theme: boolean) => void;
  setLanguage: (language: string) => void;
  setSound: (sound: boolean) => void;
  setSceneVolume: (sceneVolume: number) => void;
  setBackgroundVolume: (backgroundVolume: number) => void;
  setUserCookies: (key: string, userValues: userCookies) => void;
};
export type userCookies = {
  sound: boolean;
  sceneVolume: number;
  theme: boolean;
  language: string;
  backGroundVolume: number;
};

export const languageArr = [
  { key: "kr", value: "한국어", icon: krflag },
  { key: "cn", value: "中文", icon: chflag },
  { key: "jp", value: "日本語", icon: jpflag },
  { key: "en", value: "English", icon: enflag },
];

const CookieContext = createContext<CookiesContextType | undefined>(undefined);

export const CookiesProvider = ({ children }: { children: ReactNode }) => {
  const userData = useReactiveVar(userDataVar);
  const [theme, setThemeState] = useState<boolean>(false);
  const [language, setLanguageState] = useState<string>("");
  const [sound, setSoundState] = useState<boolean>(true);
  const [sceneVolume, setSceneVolumeState] = useState<number>(1);
  const [backgroundVolume, setBackgroundVolumeState] = useState<number>(1);
  const [userCookies, setSaveuserCookiesState] = useState<userCookies>();

  useEffect(() => {
    const savedTheme = getTheme();
    const savedLanguage = getLanguage();
    const savedSound = getSound();
    const savedSceneVolume = getSceneVolume();
    const savedBackgroundVolume = getBackgroundVolume();
    const savedUserCookies = getUserCookies(userData.user_id);

    if (savedTheme !== undefined) setThemeState(savedTheme);
    if (savedLanguage) setLanguageState(savedLanguage);
    if (savedSound !== undefined) setSoundState(savedSound);
    if (savedSceneVolume) setSceneVolumeState(+savedSceneVolume);
    if (savedBackgroundVolume) setBackgroundVolumeState(+savedBackgroundVolume);
    if (savedUserCookies !== undefined) setSaveuserCookiesState(userCookies);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateTheme = (selectedTheme: boolean) => {
    setThemeState(selectedTheme);
    setTheme(selectedTheme);
  };

  const updateSound = (sound: boolean) => {
    setSoundState(sound);
    setSound(sound);
  };

  const updateSceneVolume = (sceneVolume: number) => {
    setSceneVolumeState(sceneVolume);
    setSceneVolume(sceneVolume.toString());
  };

  const updateBackgroundVolume = (sceneBackground: number) => {
    setBackgroundVolumeState(sceneBackground);
    setBackgroundVolume(sceneBackground.toString());
  };

  const updateLanguage = (selectedLanguage: string) => {
    setLanguageState(selectedLanguage);
    setLanguage(selectedLanguage);
  };

  const updateUserCookies = (key: string, userCookies: userCookies) => {
    setSaveuserCookiesState(userCookies);
    setUserCookies(key, userCookies);
  };

  return (
    <CookieContext.Provider
      value={{
        sound,
        theme,
        language,
        sceneVolume,
        backgroundVolume,
        userCookies,
        setTheme: updateTheme,
        setLanguage: updateLanguage,
        setSound: updateSound,
        setBackgroundVolume: updateBackgroundVolume,
        setSceneVolume: updateSceneVolume,
        setUserCookies: updateUserCookies,
      }}
    >
      {children}
    </CookieContext.Provider>
  );
};

export const useCookieContext = () => {
  const context = useContext(CookieContext);
  if (!context) {
    throw new Error("useCookieContext must be used within a CookiesProvider");
  }
  return context;
};
