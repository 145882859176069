// // cookiesManager.ts

// // Define available cookie keys in an enum for better organization
// export enum CookieKeys {
//   Theme = "isDarkMode",
//   Language = "language",
// }

// import { getCookie, setCookie, setMultipleCookies } from "./cookiesUtils";

// // Function to set the theme cookie as a boolean
// export async function setTheme(theme: boolean) {
//   await setCookie(CookieKeys.Theme, theme.toString()); // Store boolean as string ("true" or "false")
// }

// // Function to get the theme cookie as a boolean
// export async function getTheme(): Promise<boolean | undefined> {
//   const theme = await getCookie(CookieKeys.Theme);
//   return theme !== undefined ? theme === "true" : undefined; // Convert to boolean
// }

// // Function to set the theme cookie
// // export async function setTheme(theme: string) {
// //   await setCookie(CookieKeys.Theme, theme);
// // }

// // // Function to get the theme cookie
// // export async function getTheme(): Promise<string | undefined> {
// //   return await getCookie(CookieKeys.Theme);
// // }

// // Function to set the language cookie
// export async function setLanguage(language: string) {
//   await setCookie(CookieKeys.Language, language);
// }

// // Function to get the language cookie
// export async function getLanguage(): Promise<string | undefined> {
//   return await getCookie(CookieKeys.Language);
// }

// // Function to set both theme and language cookies at once
// export async function setThemeAndLanguage(theme: string, language: string) {
//   await setMultipleCookies({
//     [CookieKeys.Theme]: theme,
//     [CookieKeys.Language]: language,
//   });
// }

// CLIENT SIDE ************************
// cookiesManager.ts
"use client";

import { userCookies } from "../context/CookieProvider";
import {
  getCookie,
  getUserCookie,
  setCookie,
  setUserCookie,
} from "./cookiesUtils";

export enum CookieKeys {
  Theme = "isDarkMode",
  Language = "language",
  Sound = "sound",
  SceneVolume = "sceneVolume",
  BackgroundVolume = "backgroundVolume",
}

export function setTheme(theme: boolean) {
  setCookie(CookieKeys.Theme, theme.toString());
}

export function getTheme(): boolean | undefined {
  const theme = getCookie(CookieKeys.Theme);
  return theme !== undefined ? theme === "true" : undefined;
}

export function setLanguage(language: string) {
  setCookie(CookieKeys.Language, language);
}

export function getLanguage(): string | undefined {
  return getCookie(CookieKeys.Language);
}

export function setSound(sound: boolean) {
  setCookie(CookieKeys.Sound, sound.toString());
}

export function getSound(): boolean | undefined {
  const sound = getCookie(CookieKeys.Sound);
  return sound !== undefined ? sound === "true" : undefined;
}

export function setSceneVolume(sceneVolume: string) {
  setCookie(CookieKeys.SceneVolume, sceneVolume);
}

export function getSceneVolume(): string | undefined {
  return getCookie(CookieKeys.SceneVolume);
}

export function setBackgroundVolume(backgroundVolume: string) {
  setCookie(CookieKeys.BackgroundVolume, backgroundVolume);
}

export function getBackgroundVolume(): string | undefined {
  return getCookie(CookieKeys.BackgroundVolume);
}

export function getUserCookies(userKey: string): userCookies | undefined {
  return getUserCookie(userKey);
}

export function setUserCookies(key: string, userValues: userCookies) {
  setUserCookie(key, userValues);
}
